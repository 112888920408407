const { data } = require("jquery");
const jQuery = require("jquery");

let map;

if (document.getElementById("map") && document.getElementById("relay-map")) {

    (function ($) {

        $(document).ready(function () {

            window.padMap = function () {

                // Add padding room for sidebar on desktop etc

                map.setPadding(window.getPadding());

            }

            window.getPadding = function () {

                if (window.innerWidth > 900) {

                    return { right: ($("aside").width()) };

                } else {

                    return 0;

                }

            }

            let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

            // Centre the map
            let mapCentre = [-3.0, 55.3781];

            // limit the map panning area
            let bounds = [
                [-31.092041015613233, 46.05675108705941],  // SW
                [33.77124023440794, 62.31817658249773]     // NE
            ];

            mapboxgl.accessToken = 'pk.eyJ1IjoiZGlzY292ZXJzZWFmb29kIiwiYSI6ImNrZmR3cG1jdjBoOWEydnVnZTRyMnk5aGsifQ.vsJahG3dYsrV-0ydrWnhqA';
            map = new mapboxgl.Map({

                center: mapCentre,
                zoom: window.currentPoint ? 15 : 4,
                container: 'map',
                style: 'mapbox://styles/discoverseafood/cklmfem921wrb17qlv89153i1',
                maxBounds: bounds // Sets bounds 

            });

            padMap();

            map.on('load', function () {

                $.get("/wp-json/fonf/v1/contributed_stories", function (dataRows) {

                    dataRows.forEach(function (story) {

                        let storyLocation = story.location;

                        // only show PUBLISH status stories on the map
                        let story_status = story.story_status;
                        let show_story = false;
                        if(story_status && story_status.value === "publish"){
                            show_story = true;
                        }

                        if (show_story){

                            // create a HTML element for each feature
                            var el = document.createElement('div');
                            el.className = 'relay-marker';
    
                            el.onclick = function () {
    
                                window.load_content_into_page(story.author_panel_for_story);
    
                            }
    
                            // Check if location valid
    
                            if (storyLocation) {
    
                                el.innerHTML = "<img src='" + story.image + "'/>";
    
                                // make a marker for each feature and add to the map
                                new mapboxgl.Marker(el)
                                    .setLngLat([storyLocation.lng, storyLocation.lat])
                                    .setPopup(new mapboxgl.Popup()
                                        .setHTML(`<img class="relay-popup-inner-icon" src="${themeDirectory}/images/icons/Pen_final.png"/><p><a href='${story.link}'>${story.title}</p>`))
                                    .addTo(map);
    
                            }
                        }

                    });


                })

            })

            map.on("click", function (e) {

                console.log(e.lngLat);

                // Todo - allow user to pick point on map

            })

        })

    }(jQuery))

}
