import * as turf from "@turf/turf";
import mapboxGl from "mapbox-gl/dist/mapbox-gl.js";
const jQuery = require("jquery");

let map;

if (document.getElementById("map") && !document.getElementById("relay-map")) {

  (function ($) {
    $.get("/wp-json/fonf/v1/regions", function (regions) {

      regions.forEach((element) => {

        let bbox = turf.bboxPolygon(element.bbox);
        let centre = turf.center(bbox);

        // new mapboxGl.Marker()
        // .setLngLat(centre.geometry.coordinates)
        // .addTo(map);

      })

      if (!document.querySelector(".region-template-default")) {

      map.on("idle", function () {
          if (map.getZoom() > 7) {
            let currentBounds = map.getBounds();

            regions.forEach((element) => {

              let bbox = turf.bboxPolygon(element.bbox);
              let centre = turf.center(bbox);
              let inside = currentBounds.contains(centre.geometry.coordinates);

              if (inside) {
                jQuery("#region-popup").show();
                jQuery("[data-region-name]").html(element.name);
                $("[data-region-url]").attr("href", element.url);

              }
            });
          }
        });
      }
    });

    // function for loading aside content.
    function load_content_into_page(link) {
      $.get(link + "?contentonly=true", function (content) {
        history.pushState({ link: link }, "Discover Seafood", link);

        $("aside").show().html(content);
      });
    }

    window.padMap = function () {
      // Add padding room for sidebar on desktop etc

      map.setPadding(window.getPadding());
    };

    window.getPadding = function () {
      if (window.innerWidth > 900) {
        return { right: $("aside").width() };
      } else {
        return 0;
      }
    };
    $(document).ready(function () {
      let mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");

      // do we have data in the URL set by postcode lookup?

      let searchParams = new URLSearchParams(window.location.search);
      if (
        searchParams.has("located_latitude") &&
        searchParams.has("located_longitude")
      ) {
        window.currentPoint = {};
        window.currentPoint["longitude"] =
          searchParams.get("located_longitude");
        window.currentPoint["latitude"] = searchParams.get("located_latitude");
      }

      // If there's a specific location set in the page go to it.
      // Otherwise centre on UK.
      let mapCentre = window.currentPoint
        ? [
            parseFloat(window.currentPoint.longitude),
            parseFloat(window.currentPoint.latitude),
          ]
        : [-3.0, 55.3781];

      // limit the map panning area
      let bounds = [
        [-31.092041015613233, 46.05675108705941], // SW
        [33.77124023440794, 62.31817658249773], // NE
      ];

      //OCTOPHIN mapboxgl.accessToken = 'pk.eyJ1Ijoib2N0b3BoaW4iLCJhIjoiY2s1MmtzMnFwMDllYjNtcW43bzZmZnpyNyJ9.w6pj26TwKy5wqaCQN69sFg';
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZGlzY292ZXJzZWFmb29kIiwiYSI6ImNrZmR3cG1jdjBoOWEydnVnZTRyMnk5aGsifQ.vsJahG3dYsrV-0ydrWnhqA";
      map = new mapboxgl.Map({
        center: mapCentre,
        zoom: window.currentPoint ? 15 : 4,
        container: "map",
        //style: 'mapbox://styles/octophin/ckdq0ikhl0qi31ilmva28ybrv?optimize=true',
        style:
          "mapbox://styles/discoverseafood/ckf3r0lej0xus19o1atpqnizf?optimize=true",
        // RELAY STYLE: mapbox://styles/discoverseafood/cklmfem921wrb17qlv89153i1
      });

      map.on("load", function () {
        // Check if there are any region bounds and fit to them if yes

        let bounds = document.querySelector("[data-bbox]");

        if (bounds) {
          bounds = JSON.parse(bounds.getAttribute("data-bbox"));

          map.fitBounds(bounds);
        }
      });

      // Add zoom and rotation controls to the map.
      //CT taking this off... Not needed as most interaction happens on the map.
      //map.addControl(new mapboxgl.NavigationControl());

      padMap();

      window.addGeoCoder = function () {
        // Ignore if already exits

        if (document.querySelector(".mapboxgl-ctrl-geocoder--input")) {
          return;
        }

        // Add geolocate control to the div with id 'homepage_geocoder' id it exists
        if (document.getElementById("offmap_geolocator")) {
          let geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            countries: "gb, im",
            flyTo: false,
          });

          geocoder.on("result", function (result) {
            let location = result.result.text;

            let centre = result.result.center;

            let url =
              "/wp-json/fonf/v1/get-near-point/?" +
              "latitude=" +
              centre[1] +
              "&longitude=" +
              centre[0];

            $.get(url, function (places) {
              let latlngs = places.map(function (place) {
                return [
                  place.geo_location_point_longitude,
                  place.geo_location_point_latitude,
                ];
              });

              let bounds = new mapboxgl.LngLatBounds();

              latlngs.forEach(function (coords) {
                bounds.extend(coords);
              });

              bounds.extend(centre);

              map.fitBounds(bounds, { padding: window.getPadding() });

              let nearest = `<br /><div class="nearest">`;

              places.forEach(function (place) {
                nearest += `<a class="tag-link font20" href="${place.weblink}">
                            <strong class="uppercase">
                            ${place.post_title}
                            </strong>
                            </a>`;
              });

              nearest += `</div>`;

              // If on region page we do something else
              if (document.querySelector(".region-template-default")) {

                $("#seafood-search-results").html(nearest);

              } else {
                // Remove all content aside from geocoder

                $("aside div.inner-body *")
                  .not("#offmap_geolocator,#offmap_geolocator *")
                  .remove();

                $("aside h1")
                  .text("Nearest to " + location)
                  .addClass("font45");

                $("aside div.inner-body").append(nearest);
              }
            });
          });

          document
            .getElementById("offmap_geolocator")
            .appendChild(geocoder.onAdd(map));
        }
      };

      window.addGeoCoder();

      let pointToGeoJSON = (lng, lat, properties) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
          properties: properties,
        };
      };

      let filterOutNoLatLng = (point) =>
        point.filter(
          (p) =>
            p.fields.geo_location_point.longitude &&
            p.fields.geo_location_point.latitude
        );

      let makeGeoJSONFromPoints = (name, dataRows, popupContentFunction) => {
        let geoJSON = {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
          cluster: true,
          clusterMaxZoom: 5, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        };

        // Make datarows into an array rather than an object

        dataRows = Object.keys(dataRows).map((key) => dataRows[key]);

        // Filter out rows without points

        dataRows = filterOutNoLatLng(dataRows);

        // Then map to geojson data structure

        dataRows = dataRows.map((dataRow) =>
          pointToGeoJSON(
            dataRow.fields.geo_location_point.longitude,
            dataRow.fields.geo_location_point.latitude,
            dataRow
          )
        );

        // Add features to geoJSON

        geoJSON.data.features = dataRows;

        // Add to map as source

        map.addSource(name, geoJSON);

        map.addLayer({
          id: "clusters-" + name,
          type: "circle",
          source: name,
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#e7c571",
              50,
              "#e7c571",
            ],
            "circle-radius": ["step", ["get", "point_count"], 30, 50, 40],
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          },
        });

        map.addLayer({
          id: "cluster-count-" + name,
          type: "symbol",
          source: name,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        map.addLayer({
          id: "unclustered-point-" + name,
          type: "symbol",
          source: name,
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": name + "-marker",
            "icon-size": 0.1,
          },
          properties: {
            description: "This is text for " + name,
          },
        });

        // inspect a cluster on click
        map.on("click", "clusters-" + name, function (e) {
          var features = map.queryRenderedFeatures(e.point, {
            layers: ["clusters-" + name],
          });
          var clusterId = features[0].properties.cluster_id;
          map
            .getSource(name)
            .getClusterExpansionZoom(clusterId, function (err, zoom) {
              if (err) return;
              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        map.on("click", "unclustered-point-" + name, function (e) {
          let coordinates = e.features[0].geometry.coordinates.slice();

          let url = e.features[0].properties.url;
          let post_type = e.features[0].properties.post_type;

          // don't load stories into side panel...
          if (post_type !== "contributed_story") {
            load_content_into_page(url);
          }

          map.flyTo({
            center: [coordinates[0], coordinates[1]],
            zoom: 15,
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          });

          let popupContent = "";

          // currently: places, retailer, fisher, project

          switch (post_type) {
            case "places":
              popupContent = `
                            <h2 class="barlow font20">What's available at ${e.features[0].properties.name}?</h2>
                            <nav class="popup-navigation font16 barlow flex">
                                <figure class='popup-icon-wrapper retailers'>
                                    <a href='#retailers'><img style="width:90px" src="${themeDirectory}/images/icons/retailers-white.png"><figcaption>RETAILERS</figcaption></a>
                                </figure>
                                <figure class='popup-icon-wrapper species'>
                                    <a href='#species'><img style="width:90px" src="${themeDirectory}/images/icons/scallop-crop-tight.png"/><figcaption>SPECIES</figcaption></a>
                                </figure>`;
              if (
                e.features[0].properties.related_story_count &&
                e.features[0].properties.related_story_count !== 0
              ) {
                popupContent += `
                                <figure class='popup-icon-wrapper stories'>
                                    <a href='#stories'><img style="width:90px" src="${themeDirectory}/images/icons/pen_white.png"/><figcaption>STORIES</figcaption></a>
                                </figure>
                                    `;
              }
              popupContent += "</nav>";
              break;
            case "project":
              popupContent = `Project: <a href="${url}">${e.features[0].properties.name}</a>`;
              break;
            case "contributed_story":
              console.log("TODO contributed_story");
              popupContent = `Story: <a href="${url}">${e.features[0].properties.name}</a>`;
              break;
            default:
              popupContent = `<a href="${url}">${e.features[0].properties.name}</a>`;
          }

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new mapboxgl.Popup({
            maxWidth: "320px",
            className: "map-popup-container font16 barlow",
          })
            .setLngLat(coordinates)
            .setHTML(popupContent)
            .addTo(map);
        });
      };

      map.on("click", function (e) {
        console.log(e.lngLat);

        // if the edit/add business map selection is active, fill in the fields.
        if (
          $("#acf-field_5f60c042deed9-active").parent().prop("className") ===
          "selected"
        ) {
          document.getElementById(
            "acf-field_5eabf9354fdc4-field_5eabf9764fdc6"
          ).value = e.lngLat.lat;
          document.getElementById(
            "acf-field_5eabf9354fdc4-field_5eabf9574fdc5"
          ).value = e.lngLat.lng;
        }
      });

      map.on("load", function () {
        $.get(
          "/wp-json/fonf/v1/ports?incude_related_story_count",
          function (dataRows) {
            makeGeoJSONFromPoints("port", dataRows, function (dataRow) {
              return `
                                                
                                                <b>${dataRow.name}</b>
                                                <br />
                                                
                                <a href="/${dataRow.url}">More information about this port</a>
                                
                                `;
            });
          }
        );

        $.get("/wp-json/fonf/v1/fishmongers", function (dataRows) {
          makeGeoJSONFromPoints("retailer", dataRows, function (dataRow) {
            return `
                                
                                <b>${dataRow.name}</b>
                                <br />
                
                                <a href="/${dataRow.url}">More information about this retailer<a>
                                
                                `;
          });
        });

        $.get("/wp-json/fonf/v1/fisheries", function (dataRows) {
          makeGeoJSONFromPoints("fisher", dataRows, function (dataRow) {
            return `
                                
                                <b>${dataRow.name}</b>
                                <br />
                
                                <a href="/${dataRow.url}">More information about this retailer<a>
                                
                                `;
          });
        });

        $.get("/wp-json/fonf/v1/projects", function (dataRows) {
          makeGeoJSONFromPoints("project", dataRows, function (dataRow) {
            return `
                                
                                <b>${dataRow.name}</b>
                                <br />
                
                                <a href="/${dataRow.url}">More information about this project<a>
                                
                                `;
          });
        });
      });
    });
  })(jQuery);
}
