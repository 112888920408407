const jQuery = require("jquery");

(function ($) {
    $(document).ready(function () {

        if ($('#mini-menu').is(':visible')) {

            hide_main_menu();
        }

        $('#menu-toggle').click(function () {
            event.stopPropagation();
            $('#menu-toggle').toggleClass('active');
            $('#main-menu').toggleClass('active');

        })


    })

    // This seems silly but makes the back button work following the ajax stuff below

    window.onpopstate = function () {
        document.location.href = document.location.href;
    }

    // Ajax side menu

    $(".ajax").click(function (e) {

        if($("body").hasClass("region-template-default")){
            $(".popup-close-button").trigger("click");
        }

        hide_main_menu();

        if ($("body").hasClass("reverse-colour-menu")) {

            return true;

        }

        
        let menuItem = $(this);
        let link = menuItem.attr("href");
        
        // Change logo depending on link

        let logo = "Logo_NoFish";

        if (link.indexOf("stories") !== -1) {

            logo = "Logo_Stories";

        }

        if (link.indexOf("ports") !== -1) {

            logo = "Logo_Ports";

        }

        if (link.indexOf("recipes") !== -1) {

            logo = "Logo_Recipes";

        }

        if (link.indexOf("retailers") !== -1) {

            logo = "Logo_Retailers";

        }

        if (link.indexOf("/seafood") !== -1) {

            logo = "Logo_Seafood";

        }

        $("header img.desktop-only").attr("src", window.themeDirectory + "/images/logos/PNGs/" + logo + ".png");

        load_content_into_page(link, menuItem);

        e.preventDefault();

        return false;

    });

    window.load_content_into_page = function(link, menuItem = null) {


        $.get(link + "?contentonly=true", function (content) {

            history.pushState({ link: link }, "Discover Seafood", link);

            if (menuItem) {

                // remove and set active
                $(".menu-item").removeClass("active");
                menuItem.addClass("active");

                if (menuItem.hasClass("full")) {

                    $("#primary").addClass("fullpage-base");
                    $("#full-content").show().html(content);
                    $("aside").hide();
                    $(".aside-scroller").hide();


                } else {

                    $("#primary").removeClass("fullpage-base");
                    $("aside").show().html(content);
                    $(".aside-scroller").show();
                    $("#full-content").hide();

                    // Add sidebar padding to the map

                    window.padMap();

                }

            } else {

                $("aside").show().html(content);
                $(".aside-scroller").show();

            }

            // Reload geocoder if set

            if(window.addGeoCoder){

                window.addGeoCoder();
    
            }

            $("aside").removeClass("map-active");
            $("#map-content-toggle").removeClass("map-active");
            $("body").removeClass("map-display-active");
    
    
            if (!document.querySelector("#static-content footer")) {
                document.querySelector("#static-content").insertAdjacentHTML("beforeEnd", footercopy());
            }
        });

    }

    $("body").on("click", "#main-menu", function () {

        hide_main_menu();

    });

    function hide_main_menu() {

        if ($('#mini-menu').is(':visible')) {

            $('#menu-toggle').removeClass('active');
            $('#main-menu').removeClass('active');

        }

    }

    $("body").on("keyup", "#port_search", function () {

        if ($('#port_search').val().length > 1) {

            let newContent = '<ul id="name-link-list" class="">';

            $.get("/wp-json/fonf/v1/search_load?type=places&search=" + $('#port_search').val(), function (dataRows) {

                dataRows.forEach(element => {

                    newContent = newContent + `<li class="list-item font20"><a href="${element.url}">${element.name}</a></li>`;

                });
                document.getElementById('search_results').innerHTML = newContent + '</ul>'

            });

        } else {
            document.getElementById('search_results').innerHTML = '';
        }

    });


    $("body").on("keyup", "#retailer_search", function () {
        if ($('#retailer_search').val().length > 1) {

            searchRetailer();

        } else {
            document.getElementById('search_results').innerHTML = '';
        }

    })

    $("body").on("click", "#retailer_type", function () {

        searchRetailer();

    })



    function searchRetailer() {


        var checkbox_array = [];
        $("input:checkbox[name='meta-type[]']:checked").each(function () {
            checkbox_array.push($(this).val());
        });

        let newContent = '<ul id="name-link-list" class="">';
        let url_string = `/wp-json/fonf/v1/search_load?type=retailer&search=${$('#retailer_search').val()}&meta-array-type=${checkbox_array}`;

        $.get(url_string, function (dataRows) {

            console.log("Rows Returned " + dataRows.length);

            dataRows.forEach(element => {

                newContent = newContent + `<li class="list-item font20"><a href="${element.url}">${element.name}</a></li>`;

            });
            document.getElementById('search_results').innerHTML = newContent + '</ul>'

        });



    }

    $("body").on("keyup", "#seafood_search", function () {
        if ($('#seafood_search').val().length > 0) {

            searchSeafood();

        } else {
            document.getElementById('search_results').innerHTML = '';
        }

    })

    $("body").on("change", "#season_best", function () {

        searchSeafood();

    })
    $("body").on("click", "#seafood_type", function () {

        searchSeafood();

    })

    function searchSeafood() {

        var checkbox_array = [];
        $("input:checkbox[name='meta-seafood_type[]']:checked").each(function () {
            checkbox_array.push($(this).val());
        });

        let url_string = `/wp-json/fonf/v1/search_load?type=seafood&search=${$('#seafood_search').val()}&meta-array-seafood_type=${checkbox_array}&meta-season_best=${$('#season_best').children("option:selected").val()}`;

        $.get(url_string, function (dataRows) {

            let newContent = '';
            if (dataRows.length === 0) {
                newContent = newContent + `<div class="results-count">No matching seafood.</div>`;
            } else {
                newContent = newContent + `<div class="results-count">Showing ${dataRows.length} seafood.</div>`;
            }
            newContent = newContent + '<ul id="tile-list" class="flex-wrap species-tiles">';

            dataRows.forEach(element => {

                let theImage = "/wp-content/themes/Fish-On-Friday/images/plaiceholder-line.png";
                if (element.fields.image) {

                    theImage = element.fields.image.sizes.large;
                }

                newContent = newContent + `
                
                <li class="list-item" >
                <a class="flex-column white-text" href="${element.url}">
                    <img src="${theImage}"/>
                    <h4 class="title font27">${element.name}
                    </h4>
                </a>
            </li>
                
                `;

            });

            document.getElementById('search_results').innerHTML = newContent + '</ul>'

        });


    }


    $("body").on("keyup", "#recipe_search", function () {
        if ($('#recipe_search').val().length > 0) {

            searchRecipies();

        } else {
            document.getElementById('search_results').innerHTML = '';
        }

    })

    $("body").on("change", "#meta-related_seafood", function () {

        searchRecipies();

    })

    function searchRecipies() {

        console.log($('#recipe_search').val());
        console.log($('#meta-related_seafood').children("option:selected").val());

        let url_string = `/wp-json/fonf/v1/search_load?type=recipe&search=${$('#recipe_search').val()}&meta-related_seafood=${$('#meta-related_seafood').children("option:selected").val()}`;

        $.get(url_string, function (dataRows) {

            let newContent = '';
            if (dataRows.length === 0) {
                newContent = newContent + `<div class="results-count">No matching seafood.</div>`;
            } else {
                newContent = newContent + `<div class="results-count">Showing ${dataRows.length} seafood.</div>`;
            }
            newContent = newContent + '<ul id="tile-list" class="flex-wrap recipe-tiles">';

            dataRows.forEach(element => {

                let theImage = "/wp-content/themes/Fish-On-Friday/images/missing-image-recipe.png";
                if (element.fields.recipe_image.sizes.large) {

                    theImage = element.fields.recipe_image.sizes.large;
                }

                newContent = newContent + `<li class="list-item " style="background-image: url('${theImage}');">
                <a class="flex-column white-text" href="${element.url}"><h4 class="title font20">${element.post_title}</h4></a></li>`;

            });

            document.getElementById('search_results').innerHTML = newContent + '</ul>'

        });

    }

    // extended searh box
    $(document).ready(function () {
 
        if (document.getElementsByClassName("extended-search")) {


            $("body").on("change", "#search-type", function(){

                // Clear results box
                $("#seafood-search-results").html("");

                // hide all
				jQuery(".extended-search-box").hide();

                console.log(this.value);

				// show selected
				jQuery("#" + this.value + "-search-box").show();
				// clear any results
				document.getElementById('search_results').innerHTML = '';

            })


        }
    })

    
    $("body").on("click", ".popup-close-button", function() {

        $(this).parent().hide();

    })

    $("body").on("mouseup touchend", function(){

        window.clearInterval(window.scrollingPanel);

    })

    $("body").on("mousedown touchstart", ".scroll-button--up", function(e){

        e.preventDefault();

        clearInterval(window.scrollingPanel);
        
        window.scrollingPanel = setInterval(() => {

            document.querySelector(".tank-main").scrollBy(0, -100);

        }, 100)

    })

    $("body").on("mousedown touchstart", ".scroll-button--down", function(e){

        e.preventDefault();

        clearInterval(window.scrollingPanel);
        
        window.scrollingPanel = setInterval(() => {
            
            document.querySelector(".tank-main").scrollBy(0, 100);

        }, 100)


    })

    $("body").on("click", "#map-content-toggle", () => {


        $("aside").toggleClass("map-active");
        $("#map-content-toggle").toggleClass("map-active");
        $("body").toggleClass("map-display-active");


    })

    $("body").on("click", ".mapboxgl-popup-content .popup-navigation a", () => {
        $("aside").toggleClass("map-active");
        $("#map-content-toggle").toggleClass("map-active");
        $("body").toggleClass("map-display-active");
    })

    function footercopy () {
        const footer = document.querySelector("footer");
        return footer.cloneNode(true).outerHTML;
    }

    document.querySelector("#static-content").insertAdjacentHTML("beforeEnd", footercopy());
    document.querySelector("#primary").insertAdjacentHTML("beforeEnd", footercopy());

    // for search boxes (multiple pages), if user clicks back to the page, 
    // show the relevant search box for previous selection.
    window.addEventListener("pageshow", () => {

        let preValue = jQuery("#search-type").find(":selected").val();

        if(preValue){
            // hide all
			jQuery(".extended-search-box").hide();
            
            // show this..
            jQuery("#" + preValue + "-search-box").show();

        }
    
    });

}(jQuery))
